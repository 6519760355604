import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const AboutPage = ({ data, location }) => (
  <Layout mainClassName="about-page">
    <SEO
      title="About YouTube Video Finder"
      description="The journey of the YouTube Video Finder project"
      pathname={location.pathname}
    />
    <section>
      <div className="inner">
        <h1 className="text__l">
          Private or deleted video in your YouTube playlist?
        </h1>
        <p>
          That's exactly what happened to me too! And I started looking for a
          way how to recover at least <strong>title of the lost video</strong>.
          Unfortunately there is <strong>no 100% method</strong> to do it, just
          a few "tricks"...
        </p>
        <p>
          I really wanted to prevent losing another YouTube video again. Then I
          discovered possibility of <strong>YouTube playlists backup</strong>{" "}
          and build a tool to{" "}
          <strong>recover private or deleted YouTube video informations</strong>{" "}
          (such as title and description) from the backup. Now I just want to{" "}
          share it with you, hoping it will be as helpful as it is for me!
        </p>
        <p>
          In case of some issues or feature requests, feel free to contact me at{" "}
          <a href={`mailto:${data.site?.siteMetadata?.contactEmail}`}>
            {data.site?.siteMetadata?.contactEmail}
          </a>{" "}
          or use <Link to="/feedback/">feedback page</Link>.
        </p>
      </div>
    </section>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        contactEmail
      }
    }
  }
`
